<template>
  <b-card
    no-body
    class="p-3"
  >
    <b-row align-h="center">
      <b-col
        cols="6"
      >
        <validation-observer ref="formRef">
          <b-form @submit.prevent>
            <b-row>
              <!-- Invoice Field -->
              <b-col cols="12">
                <b-form-group
                  :label="$t('globalSingular.code')"
                  label-for="code"
                >
                    <b-form-input
                      id="code"
                      size="sm"
                      v-model="form.code"
                      :placeholder="$t('globalSingular.auto')"
                      :disabled="actions.isPreview"
                    />
                </b-form-group>
              </b-col>

              <!-- Date Field -->
              <b-col cols="12">
                <b-form-group
                  :label="$t('globalSingular.date')"
                  label-for="date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="date"
                    rules="required"
                  >
                    <flat-pickr
                      id="date"
                      :config="dateFormat"
                      v-model="form.date"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      class="form-control form-control-sm"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="my-1">
              <!-- Transaction No Field -->
              <b-col cols="12" md="8">
                <b-form-group
                  :label="$t(`globalSingular.supplier`)"
                  label-for="contacts"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="contact"
                    rules="required"
                  >
                    <v-select
                      class="select-size-sm"
                      id="contacts"
                      v-model="form.contactId"
                      :options="LOV.contacts"
                      :reduce="field => field.id"
                      label="label"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="4" class="d-flex align-items-center justify-content-end">
                <b-button
                  class="mb-sm-1 mb-md-0"
                  :variant="actions.isPreview ? 'outline-secondary' : 'primary'"
                  :disabled="!form.contactId"
                  @click="getOutstandingInvoice(form.contactId)"
                >
                  <!-- <feather-icon
                    icon="ArrowLeftIcon"
                  /> -->
                  {{ $t('globalActions.find') }}
                  <!-- Generate -->
                </b-button>
              </b-col>
            </b-row>

            <b-row v-if="form.details.length > 0">
              <b-col sm="12">
                <b-list-group class="tabular-numeric">
                  <b-list-group-item class="d-flex align-items-center justify-content-between" v-for="(invoice, index) in form.details" :key="invoice.id">
                    <div>
                      <span class="mr-1">
                        {{ index + 1 }}
                      </span>
                      <span>{{ invoice.invoiceNo }}</span>
                    </div>

                    <div class="d-flex align-items-center">
                      <span class="mr-2">{{ formatCurrency(invoice.outstandingAmount) }}</span>
                      <b-form-checkbox
                        v-model="form.details[index].checked"
                        name="check-button"
                        inline
                      >
                      </b-form-checkbox>
                    </div>
                  </b-list-group-item>
                  <b-list-group-item class="text-center" v-if="!form.details.length">
                    <div>{{ $t('globalAlerts.noUserFound') }}</div>
                    <div>{{ $t('globalAlerts.subTitle.noUserFound') }}</div>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row>


            <b-row class="mt-2">
              <b-col cols="12" md="12">
                <b-form-group
                  :label="$t('globalSingular.notes')"
                  label-for="notes"
                >
                  <b-form-textarea
                    id="notes"
                    size="sm"
                    v-model="form.description"
                    :placeholder="!actions.isPreview ? $t('globalSingular.description') : null"
                    :disabled="actions.isPreview"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
              <b-button
                class="mb-sm-1 mb-md-0"
                :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                :to="{ name: 'apps-transactions-purchase-payment-voucher-list' }"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                />
                {{ $t('globalActions.backToList') }}
              </b-button>

              <b-button
                v-if="actions.isPreview"
                class=" mb-sm-1 mb-md-0"
                variant="outline-danger"
                @click="handleDelete"
              >
                <feather-icon
                  icon="Trash2Icon"
                />
                {{ $t('globalActions.delete') }}
              </b-button>

              <b-button
                v-if="actions.isPreview"
                class=" mb-sm-1 mb-md-0"
                variant="primary"
                 :to="{ name: 'apps-transactions-purchase-payment-voucher-report', params: { id: paramsId }}"
              >
                <feather-icon
                  icon="ArchiveIcon"
                />
                {{ $t('globalSingular.reports') }}
              </b-button>

              <b-button
                v-if="!actions.isPreview"
                variant="primary"
                type="submit"
                @click="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                />
                {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
              </b-button>

              <b-button
                v-else
                variant="outline-primary"
                @click="changeToEdit"
              >
                <feather-icon
                  icon="EditIcon"
                />
                {{ $t('globalActions.changeToEdit') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  // BTable,
  // BTr,
  // BTd,
  BListGroup,
  BListGroupItem,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import Cleave from 'vue-cleave-component'
import { ref, onMounted, computed } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, maxValue } from '@validations'
import { formatCurrency } from '@/utils/formatter'
import VuexStore from '@/store'


import useFormResource from '@/comp-functions/useFormResource'
import usePushTags from '@/comp-functions/utils/usePushTags'
import useLocalization from '@/comp-functions/utils/useLocalization'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // BTable,
    // BTr,
    // BTd,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr
    // Cleave
  },
  setup () {
    const {
      paramsId,
      // routeParams,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({ url: 'transaction/purchase/payment-voucher', localeContextPath: 'apps.transactions.purchase.payment.singular.payment', redirectPathName: 'apps-transactions-purchase-payment-voucher' })

    const company = computed(() => {
      return VuexStore.state.user.currentCompany
    })

    const { $t } = useLocalization()

    const LOV = ref({
      cashbankAccounts: [],
      cashbankCodeAccounts: [],
      contacts: [],
      accounts: [],
      tags: [],
      outstandingInvoices: []
    })

    const getContacts = async () => {
      LOV.value.contacts = await get({ url: 'value/contact/supplier'})
    }

    const tableColumns = ref([])

    const form = ref({
      accountId: '',
      cashbankId: '',
      contactId: '',
      code: '',
      paymentTypeCode: '',
      date: '',
      tags: [],
      description: '',
      amount: 0,
      deductionAmount: 0,
      details: []
    })

    const getOutstandingInvoice = async id => {
      LOV.value.outstandingInvoices = await get({ url: `value/outstanding-purchase-invoice?id=${id}`})
      form.value.details = LOV.value.outstandingInvoices.map((invoice, index) => {
        return {
          rowNumber: index++,
          invoiceId: invoice.id,
          invoiceNo: invoice.code,
          billAmount: parseFloat(invoice.sum_of_bill_amount) || 0,
          outstandingAmount: parseFloat(invoice.outstanding_amount) || 0,
          amount: parseFloat(invoice.amount) || 0,
          deductionAccountId: null,
          deductionAmount: 0
        }
      })
    }

    const dateFormat = ref({ dateFormat: 'd/m/Y', allowInput: true })

    const numeric = ref({
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralPositiveOnly: true
    })

    /**
     * @remark sum of amount and deduction amount when these value change
    */
    const sumAmount = () => {
      form.value.amount = form.value.details.reduce((accumulator, {amount}) => accumulator + amount, 0)
      form.value.deductionAmount = form.value.details.reduce((accumulator, {deductionAmount}) => accumulator + deductionAmount, 0)
    }

    // will get data to show the preview
    const getDataPreview = async () => {
      const data = await get({ url: `transaction/purchase/payment-voucher/${paramsId}`})
      // getOutstandingInvoice(data.contact_id)
      form.value.accountId = data.account_id
      if (company.value.plan_id > 2) {
        form.value.cashbankId = data.cashbank_id
      }
      form.value.accountId = data.account_id
      form.value.contactId = data.contact_id
      form.value.code = data.code
      form.value.paymentTypeCode = data.payment_type_code
      form.value.date = data.date
      form.value.dueDate = data.due_date
      form.value.tags = data.tags.map(tag => parseInt(tag))
      form.value.description = data.description
      form.value.amount = parseFloat(data.amount)
      form.value.deductionAmount = parseFloat(data.deduction_amount)
      form.value.details = data.details.map(field => {
        const deductionAmount = parseFloat(field.deduction_amount)
        return {
          checked: true,
          rowNumber: field.row_number,
          invoiceId: field.invoice_id,
          invoiceNo: field.invoice_no,
          billAmount: parseFloat(field.bill_amount),
          outstandingAmount: parseFloat(field.outstanding_amount),
          amount: parseFloat(field.amount),
          deductionAccountId: field.deduction_account_id,
          deductionAmount
        }
      })
    }

    // add deduction amount
    const addDeduction = () => {
      form.value.details.push({
        rowNumber: form.value.details.length + 1,
        invoiceId: null,
        invoiceNo: '',
        billAmount: 0,
        outstandingAmount: 0,
        amount: 0,
        deductionAccountId: null,
        deductionAmount: null
      })
    }

    const totalMoneyWillReceive = computed(() => {
      const total = form.value.amount - form.value.deductionAmount
      return formatCurrency(total)
    })

    const detailInvoicePayment = computed(() => form.value.details.filter(field => field.billAmount > 0))
    const deductionPayment = computed(() => form.value.details.filter(field => field.billAmount === 0))

    // Remove row deduction / payment list
    const removeRow =  (rowNumber) => {
      const index = form.value.details.findIndex(field => field.rowNumber === rowNumber)
      form.value.details.splice(index, 1)
      sumAmount()
    }

    onMounted(() => {
      getContacts()
      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
      }
      tableColumns.value = [
        // { key: 'rowNumber', label: '#', width: '10%', thClass: 'bg-transparent pl-0', tdClass: 'align-top pl-0' },
        { key: 'invoiceNo', label: $t('apps.transactions.purchase.payment.singular.invoiceNo'), width: '24%', thClass: 'bg-transparent pl-0 width-25-per', tdClass: 'align-top pl-0' },
        { key: 'billAmount', label: $t('apps.transactions.purchase.payment.singular.invoiceAmount'), width: '24%', thClass: 'bg-transparent text-right width-25-per', tdClass: 'align-top text-right'},
        { key: 'outstandingAmount', label: $t('apps.transactions.purchase.payment.singular.outstandingAmount'), width: '24%', thClass: 'bg-transparent text-right width-25-per', tdClass: 'align-top text-right'},
        { key: 'amount', label: $t('apps.transactions.purchase.payment.singular.paymentAmount'), width: '24%', thClass: 'bg-transparent text-right width-25-per', tdClass: 'align-top text-right'},
        { key: 'actions', width: '12%', thClass: 'bg-transparent text-right width-10-per  pr-0', tdClass: 'align-top text-right pr-0'}
      ]
    })

    const { pushTagsToAPI } = usePushTags()
    const pushTags = async newTag => {
      const id = await pushTagsToAPI(newTag)
      form.value.tags[form.value.tags.findIndex(val => val === newTag.id)] = id
    }

    return {
      company,
      getOutstandingInvoice,
      pushTags,
      required,
      maxValue,
      LOV,
      tableColumns,
      detailInvoicePayment,
      deductionPayment,
      form,
      addDeduction,
      removeRow,
      totalMoneyWillReceive,
      dateFormat,
      formatCurrency,
      sumAmount,
      numeric,
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    }
  },
  methods: {
    handleDelete () {
      this.destroy(this.$swal)
    },
    handleSubmit () {
      this.form.details = this.form.details.filter(field => field.checked)
      this.store({
        $swal: this.$swal,
        data: this.form
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.tabular-numeric {
  font-variant-numeric: tabular-nums;
}
</style>
